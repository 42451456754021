var tempTableData = [];
var selectRowData = [];
import {
	Message
} from 'element-ui'
import {
	handlePageQueryUsers,
	handleUserLogoff,
} from '@manage/api/bm/usermanage';

import {
	handleGetSms
} from '@index/api/login';
import common from '@index/utils/common';
export default {
	name: 'usermanage',
	components: {},
	data() {
		return {
			loginCodeButton: {
				name: "获取验证码",
				disabled: false
			},
			dialogVisible: false,
			form: { smsId: '' },
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: {},
			tableData: [],
			timer: null,
		}
	},
	watch: {

	},
	created() {
		this.fastQuery();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQueryUsers({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con: this.queryForm.con,
				type: '1',
				sortWay: [{
					fieldName: 'createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					tempTableData = res.data;
					this.tableData = tempTableData;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},

		openDialog(id, phone) { //打开修改对话框
			this.dialogVisible = true;
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
			this.loginCodeButton = {
				name: "获取验证码",
				disabled: false
			};
			this.form = { id: id, phone: phone };
		},

		getSmsCodeeEvent() { //获取登陆验证码
			console.log(">>> this.form>>>" + JSON.stringify(this.form));
			var phone = this.form.phone;
			let thisObj = this;
			this.getSmsCode(phone, function (res) {
				thisObj.form.smsId = res;
				var time = 30;
				thisObj.loginCodeButton = {
					name: time + "s",
					disabled: true
				};

				thisObj.timer = setInterval(function () {
					if (--time >= 0) {
						thisObj.loginCodeButton = {
							name: time + "s",
							disabled: true
						}
					} else {
						thisObj.loginCodeButton = {
							name: "获取验证码",
							disabled: false
						};
						if (thisObj.timer) {
							clearInterval(thisObj.timer);
						}

					}
				}, 1000)
			});
		},
		getSmsCode(phone, callback) { //获取短信验证码
			try { //验证
				common.requiredValidate([{
					value: phone,
					tips: "电话不能为空!"
				}]);
			} catch (e) {
				this.$message({
					type: 'error',
					message: e
				});
				return;
			}

			handleGetSms(
				phone
			).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: '发送成功请注意查收!'
					});
					callback(res.data.smsId);
					//   this.form.smsId = res.data.smsId;
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}

			});

		},

		submitInfo() { //用户注销
			var requestParam = { ...this.form };
			requestParam.userId = this.form.id;
			console.log("1111>this.form>>>>" + JSON.stringify(requestParam));
			handleUserLogoff(requestParam).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.dialogVisible = false;
					this.form = {};
					this.queryForm = { con: '' };
					this.loginCodeButton = {
						name: "获取验证码",
						disabled: false
					};
					this.fastQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		}
		// logoutAccountEvent() {
		// 	if (!this.currSelectRow) {
		// 		Message({
		// 			message: '请选择数据!',
		// 			type: 'error',
		// 			duration: 5 * 1000
		// 		});
		// 		return;
		// 	}
		// 	Message({
		// 		message: '此功能还在开发中!',
		// 		type: 'success',
		// 		duration: 5 * 1000
		// 	});
		// }
	}
}